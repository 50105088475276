/* footer-bottom-search */

.footer-search ul {
    display: flex;
    justify-content: space-between;
    padding: 12px 14px;
}

.footer-search ul li {
    text-align: center;
    font-family: "Inter";
}

.footer-search {
    background: #fff;
    bottom: 0;
    box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 15%);
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.footer-search ul li figure {
    margin-bottom: 5px;
}

.footer-search ul li a {
    color: #000;
    font-size: 13px;
}

.footer-search ul li button:active,
.footer-search ul li button:hover {
    background-color: transparent !important;
    /* color: #F06F00!important;
    color: #000!important; */
}

.footer-search ul li button {
    background: transparent;
    border: none;
    color: #000;
    font-size: 13px;
    padding: 0px;
}

.footer-search ul li img {
    filter: brightness(0);
    max-height: 19px;
}

/* .footer-search ul li figure img {
    filter: brightness(0);
} */
/* .footer-search ul li .search-bm img{
    filter: brightness(0.7);
} */
.footer-search ul li.activeTabs figure img {
    filter: initial;
}

.footer-search ul li:hover .btn {
    color: #000 !important;
}

.footer-search ul li.activeTabs .btn {
    color: #F06F00 !important;
}

.footer-search ul li button .notification-count {
    width: 8px;
    height: 8px;
    background: #b52525;
    display: block;
    position: absolute;
    border-radius: 100%;
    top: -3px;
    right: 25px;
}

/* footer-bottom-search */


.view-cart-wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    width: 96%;
    /* padding: 7px 23px; */
    padding: 3px 23px 7px 23px;
    margin: 0 auto;
    left: 0;
    right: 0;
    background: #1c1b1a;
    /* border-radius: 50px; */
    border-radius: 10px;
    box-shadow: 0 -2px 7px 0 rgb(0 0 0 / 22%);
    z-index: 14;
    bottom: 18px;
}

.view-cart-wrapper h2 {
    font-size: 18px;
    margin: 0;
}

.view-cart-wrapper h2 img {
    margin-right: 7px;
}

.view-cart-wrapper span {
    font-size: 18px;
}

.view-item-wrapper {
    text-align: center;
}

.view-item-wrapper span {
    font-size: 14px;
}

.view-item-wrapper h5 {
    font-size: 18px;
    margin: 0;
}


@media(max-width:991px) {
    .restra-menu-sec .rest-detail {
        width: 100%;
        margin: 141px 0px 21px;
    }

    .rest-detail {
        width: 100%;
        margin: 106px 0px 21px;
    }

    .view-cart-wrapper {
        bottom: 20px;
        background: var(--btnColor);
    }
}

@media(max-width:767px) {

    .rest-debit-wrapper {
        flex: 100%;
        max-width: 100%;
    }

    .rest-detail .common-btn {
        padding: 7px 11px 7px 11px;
        font-size: 14px;
    }

    .view-cart-wrapper {
        bottom: 74px;
    }

}

@media(max-width:575px) {
    .view-cart-wrapper h2 {
        font-size: 16px;
    }


}