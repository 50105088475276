.add-cart-inner-modal textarea {
  height: 119px;
}

.quantity-actions {
  max-width: 172px;
  border: 1px solid #dadada;
  width: 100%;
  border-radius: 8px;
  flex: 172px;
  margin-right: 18px;
}

.quantity-actions button {
  background: transparent !important;
  border: none;
  color: #000 !important;
  font-size: 25px;
  width: 30px;
  width: 90px;
  padding: 0px;
}

.quantity-actions .form-control {
  width: 50%;
  border: none;
  padding: 6px 6px;
  text-align: center;
  height: 55px;
}

.modal-bottom-actions {
  display: flex;
  /* justify-content: space-between; */
}

.modal-bottom-actions .common-btn {
  flex: 1;
  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
}

.quantity-actions button:active {
  background-color: transparent;
  color: #000;
}

/* build-own-modal */

.build-own label {
  font-size: 23px;
  width: 100%;
  cursor: pointer;
}
.nested-price-wrapper svg {
  margin-left: 7px;
}

.build-own .MuiFormLabel-root {
  font-size: 20px;
  color: #000;
  margin-bottom: 7px;
}
.build-own .MuiFormControlLabel-label {
  width: 100%;
}
.salad-wrapper .price {
  font-size: 15px;
  margin-right: 14px;
}

.salad-wrapper .MuiSvgIcon-root {
  font-size: 15px;
  color: #00000052;
}

.build-own h4 {
  font-size: 19px;
  font-weight: 700;
}

.selection-required {
  border: 1px solid #00000030;
  padding: 12px;
  margin: 7px 0px;
}

.build-own .edit-selection {
  cursor: pointer;
}
.selection-required span {
  display: block;
}
.edit-selection .MuiSvgIcon-root {
  color: #000;
  font-weight: 900;
  font-size: 17px;
}
/* build-own-modal */

/* nesting-modal */

.nesting-modal {
  position: absolute;
  top: -87px;
  background: #fff;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1111;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.choose-option .choose-option-inner {
  background: rgb(246, 246, 246);
  padding: 14px 33px;
  margin: 0px -22px 11px;
}
.choose-option .choose-option-inner h5 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 2px;
}

.choose-option .choose-option-inner span {
  font-size: 15px;
  color: #545454;
  font-family: "Inter";
}

.nesting-modal h4 {
  padding: 22px 15px 17px  15px;
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.nesting-modal .MuiFormGroup-root {
  padding: 5px 14px;
}
.nesting-wrapper {
  overflow-y: auto;
  max-height: calc(100% - 121px);
  padding: 0px 22px;
  padding-bottom: 21px;
  /* min-height: 360px; */
  overflow-x: hidden;
  flex: 1;
}
.nesting-footer {
  margin: 19px 15px;
  margin-bottom: 24px;
}
.nesting-footer button {
  height: 56px;
}
.add-to-order {
  font-size: 16px !important;
}
.Mui-checked {
  color: var(--btnColor);
}
/* nesting-modal */

.build-own-li .dropdown-toggle::after {
  position: absolute;
  top: 55%;
  right: 19px;
  transform: translateY(-50%);
  margin-top: 0px;
}
.product-data-sec .dropdown-toggle {
  width: 100%;
  height: 100%;
  padding: 0px 21px !important;
}

/* 27/7/23 */

.addons-modal-banner {
  height: 335px;
}
.addons-modal-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.addons-modal-banner .modal-header svg {
  position: absolute;
  top: 0px;
}

.cross-btn {
  position: absolute !important;
  top: 33px !important;
  background: #fff !important;
  right: 20px !important;
}
.cbtn {
  top: 21px !important;
}
.cross-btn:hover {
  background: #fff !important;
}

/* 27/7/23 */

.first-level-modal .modal-body .add-cart-inner-modal {
  overflow: hidden !important;
  max-height: none !important;
  /* padding: 27px 8px 20px 5px !important; */
  padding: 10px 8px 20px 5px !important;
}

.second-level-modal .modal-body .add-cart-inner-modal {
  overflow: hidden !important;
  padding: 27px 8px 20px 5px !important;
}
.modal-open {
  overflow: hidden !important;
}

.modal-open .modal {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.product-modal-heading {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1111;
  border-radius: 0;
}

.modal-content {
  max-height: calc(100vh - 32px);
  /* overflow-y: scroll !important; */
}

.modal-bottom-actions {
  position: absolute;
  bottom: 0px;
  background: #fff;
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  z-index: 1111;
}
.product-modal-wrapper {
  overflow-y: scroll !important;
  /* min-height: 860px; */
}
.product-modal-wrapper::-webkit-scrollbar{
  width: 0px;
}

.product-modal-wrapper.second-level-block {
  min-height: auto;
}

.second-level-block .add-cart-inner-modal {
  /* max-height: none 100% !important; */
}

.rest-list-modal .modal-body .add-cart-inner-modal {
  max-height: 500px;
  overflow-y: auto;
  padding: 0 8px 0 5px;
}

.p-item:last-child {
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.show-desc {
  display: block;
  animation: fadeIn 0.5s;
}
.hide-desc {
  display: none !important;
  animation: fadeIn 0.5s;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f06f00 !important;
}
