.sidenav {
    background-color: #fff;
    /* box-shadow: 0 2px 10px 0 rgba(0,0,0,.08); */
    height: 100%;
    overflow-x: hidden;
    padding-top: 5px;
    position: fixed;
    top: 0;
    left: -280px;
    transition: all .3s ease-in-out!important;
    width: 276px;
    z-index: 16;
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 24px;
}
.sidenav.open {
    left: 0;
    height: calc(100% - 77px);
    top: auto;
    bottom: 0;
}

.sidenav.visible-msg {
    height: calc(100% - 111px);
  }

.sidenav:hover{
    width: 276px;
    transition: all .3s ease-in-out!important;
}

.sidenav:hover small{
   opacity: 1;
   transition: all 0s ease-in-out;
}


.sidenav li a {
    display: block;
    width: 100%;
    padding: 13px 14px;
    color: #1c1714!important;
    font-size: 17px;
    font-family: "Inter";
    font-weight: 700;
}

.sidenav li a span {
    display: inline-block;
    /* margin-right: 16px; */
    width: 23px;
}
.sidenav li a.active{
    color: var(--btnColor)!important;
}
/* .sidenav li a.active img {
    -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
} */



.close-menu {
    position: fixed!important;
    top: 113px;
transition: all 0.3s ease-in-out 0s !important;
    z-index: 2;
    background-color: #000!important;
    color: #fff!important;
}
.close-menu-new {
    position: fixed!important;
    top: 126px;
    transition: all 0.3s ease-in-out 0s !important;
    z-index: 2;
    background-color: #000!important;
    color: #fff!important;
}

.sidenav li {
    border-bottom: 1px solid #00000012;
    position: relative;
}

.sidenav small {
    position: absolute;
    left: 50px;
    display: inline-block;
    width: 100%;
    transition: all .3s ease-in-out;
    top: 50%;
    transform: translateY(-50%);
}

.sidenav li a span img {
    filter: brightness(.2);
}
.sidenav li a:hover{
    background-color: rgb(247, 247, 247);
}

/* my-account-dropdown */

.sidenav .dropdown-toggle {
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
    color: #1c1714;
    font-size: 17px;
    font-family: 'Inter';
    font-weight: 700;
    padding: 10px 15px;
}

.sidenav .dropdown-menu {
    position: initial!important;
    transform: translate(0)!important;
    border: none;
}

.sidenav .dropdown-toggle:active {
    background: transparent;
    border: none;
    color: #000;
}
.sidenav .dropdown-toggle img {
    filter: brightness(.2);
}
.sidenav .dropdown-toggle strong {
    margin-left: 17px;
    font-size: 15px;
}
.sidenav .dropdown-menu a {
    font-size: 14px;
    padding: 9px 52px;
}
.sidenav .dropdown-menu a:first-child {
    padding-top: 0px;
}
.sidenav .dropdown-menu a:last-child {
    border: none;
}
/* my-account-dropdown */